import * as React from "react"
import config from "../../config"
import { navigate } from "gatsby"
import { BsBarChartFill } from "@react-icons/all-files/bs/BsBarChartFill"
import { BsClockFill } from "@react-icons/all-files/bs/BsClockFill"
import { BsStarFill } from "@react-icons/all-files/bs/BsStarFill"
import { FaHashtag } from "@react-icons/all-files/fa/FaHashtag"
import { StaticImage } from "gatsby-plugin-image"
import { TbLetterV } from "react-icons/tb"

const ContentCard = ({ userHasSuscription, card }) => {
  const [contenidooPremium, setContenidoPremium] = React.useState("premium")
  const [userIsPremium, setUserIsPremium] = React.useState("premium")
  const [content, setContent] = React.useState([])
  const [cardPortrait, setCardPortrait] = React.useState()

  React.useEffect(() => {
    if (userHasSuscription !== undefined) {
      setUserIsPremium(userHasSuscription === "premium" ? "premium" : "free")
    }
  }, [userHasSuscription])

  React.useEffect(() => {
    if (card) {
      const isPremium =
        card.contenidoPremium || card.tipoContenido === "premium"
      setContenidoPremium(isPremium ? "premium" : false)

      if (card.imagen) {
        setCardPortrait(
          card.imagen.url[0] !== "/"
            ? card.imagen.url
            : `${config.serverUri}${card.imagen.url}`
        )
      } else if (card.portada) {
        setCardPortrait(
          card.portada.url[0] !== "/"
            ? card.portada.url
            : `${config.serverUri}${card.portada.url}`
        )
      } else {
        setCardPortrait(`${config.serverUri}/uploads/Funcional_86a7cd6f22.jpg`)
      }
    }
  }, [card])

  React.useEffect(() => {
    if (card && card.tipoDeContenido) {
      switch (card.tipoDeContenido) {
        case "workout":
          setContent(card.entrenamiento || [])
          break
        case "live":
          setContent(card.live || [])
          break
        case "audio":
          setContent(card.audio || [])
          break
        case "tip":
          setContent(card.tip || [])
          break
        case "plan":
          setContent(card.plan || [])
          break
        default:
          setContent([])
          break
      }
    }
  }, [card])

  const handleAction = () => {
    if (card) {
      const contentType = card.tipoDeContenido

      switch (contentType) {
        case "workout":
          triggerWorkoutView(card.entrenamiento || {})
          break
        case "audio":
          triggerAudioView(card.audio || {})
          break
        case "plan":
          triggerPlanView(card.plan || {})
          break
        case "live":
          triggerLiveView(card.live || {})
          break
        default:
          triggerUndefinedView()
          break
      }
    } else {
      triggerUndefinedView()
    }
  }

  const truncateDescription = str =>
    str ? (str.length > 80 ? str.substring(0, 80) + "..." : str) : ""

  const truncateTitle = str =>
    str ? (str.length > 30 ? str.substring(0, 30) + "..." : str) : ""

  const triggerWorkoutView = workout => {
    console.log(workout)
    if (workout.live) {
      navigate(`/content/lives/${workout.live.id}`)
    } else if (workout.id) {
      navigate(`/content/workouts/${workout.url_slug}`)
    } else {
      navigate(`/content/unavailable`)
    }
  }

  const triggerAudioView = audio => {
    if (audio.id) {
      navigate(`/content/audio/${audio.url_slug}`)
    } else {
      navigate(`/content/unavailable`)
    }
  }

  const triggerPlanView = plan => {
    if (plan.id) {
      navigate(`/content/plan/${plan.id}`)
    } else {
      navigate(`/content/unavailable`)
    }
  }

  const triggerLiveView = live => {
    if (live.id) {
      navigate(`/content/lives/${live.id}`)
    } else {
      navigate(`/content/unavailable`)
    }
  }

  const triggerUndefinedView = () => {
    navigate(`/content/unavailable`)
  }

  const formatDate = fechaInicio => {
    if (fechaInicio) {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
      ]
      const fecha = new Date(fechaInicio)
      const hora =
        fecha.getHours() >= 12
          ? `${fecha.getHours() - 12}:${fecha.getMinutes()}PM`
          : `${fecha.getHours()}:${fecha.getMinutes()}AM`

      return `${days[fecha.getDay()]} ${fecha.getDate()}  ${hora}`
    }
    return ""
  }

  return (
    <div
      className="pt-1 pl-2 sm:full"
      style={{ width: 344, cursor: "pointer" }}
      onClick={handleAction}
    >
      <div
        className="flex relative"
        style={{
          height: card.titulo ? 198 : 198,
          width: card.titulo ? "100%" : "100%",
        }}
      >
        {userIsPremium ? (
          userIsPremium !== "premium" &&
          (contenidooPremium || contenidooPremium === "premium") ? (
            <div
              className="absolute z-10 w-10/12"
              style={{ left: "-6px", top: "-5px" }}
            >
              <StaticImage
                src={`../../images/premium-ml.png`}
                alt="Premium content"
                objectFit="contain"
              />
            </div>
          ) : null
        ) : null}

        {cardPortrait ? (
          <img
            className="rounded"
            src={cardPortrait}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt={card.titulo ?? card.nombre}
          />
        ) : (
          <img
            className="rounded-xl"
            src={`${config.serverUri}/uploads/Funcional_86a7cd6f22.jpg`}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt={card.nombre}
          />
        )}
        <div className="absolute w-full h-full px-4 py-2">
          <div className="flex flex-col justify-between w-full h-full">
            {card.tipoDeContenido === "workout" ? (
              <div className="flex justify-end text-white">
                <p className="mb-2">Ver rutina</p>
              </div>
            ) : (
              <div className="flex justify-end text-transparent">
                <p className="mb-2 invisible">Espacio reservado</p>
              </div>
            )}

            <p
              className="text-white"
              style={{
                fontSize: 24,
                marginBottom: 0,
                lineHeight: 1.3,
                fontWeight: "bolder",
              }}
            >
              {card.titulo
                ? truncateTitle(card.titulo)
                : truncateTitle(card.nombre)}
            </p>

            <div className="flex text-white items-end">
              {card ? (
                <>
                  {card.tipoDeContenido !== "plan" &&
                  card.tipoDeContenido !== "audio" ? (
                    <div className="mr-5 text-center">
                      <div className="flex justify-center items-center">
                        <BsBarChartFill></BsBarChartFill>
                      </div>
                      <p style={{ fontSize: 10, marginBottom: 0 }}>
                        {card.nivel !== null && card.nivel !== undefined
                          ? card.nivel
                          : content &&
                            content.nivel !== null &&
                            content.nivel !== undefined
                          ? content.nivel
                          : card.entrenamiento &&
                            card.entrenamiento.nivel !== null &&
                            card.entrenamiento.nivel !== undefined
                          ? card.entrenamiento.nivel
                          : "?"}
                      </p>
                    </div>
                  ) : null}
                  {card.tipoDeContenido === "plan" ? (
                    <div className="mr-5 text-center">
                      <div className="flex justify-center items-center">
                        <FaHashtag></FaHashtag>
                      </div>
                      <p style={{ fontSize: 10, marginBottom: 0 }}>
                        {card.plan &&
                        card.plan.entrenamientos &&
                        card.plan.entrenamientos.length
                          ? `${card.plan.entrenamientos.length} clases`
                          : "0 clases"}
                      </p>
                    </div>
                  ) : (
                    <>
                      {card.duracion !== undefined ||
                      (content && content.duracion !== undefined) ? (
                        <div className="mr-5 text-center">
                          <div className="flex justify-center items-center">
                            <BsClockFill></BsClockFill>
                          </div>
                          <p style={{ fontSize: 10, marginBottom: 0 }}>
                            {card.duracion !== undefined
                              ? `${card.duracion} min`
                              : content && content.duracion !== undefined
                              ? `${content.duracion} min`
                              : "?"}
                          </p>
                        </div>
                      ) : null}
                    </>
                  )}
                  {card.tipoDeContenido === "workout" &&
                  card.entrenamiento &&
                  card.entrenamiento.volumen &&
                  card.entrenamiento.volumen.trim() !== "" ? (
                    <div className="mr-5 text-center">
                      <div className="flex justify-center items-center bold">
                        <TbLetterV></TbLetterV>
                      </div>
                      <p style={{ fontSize: 10, marginBottom: 0 }}>
                        {card.entrenamiento.volumen}
                      </p>
                    </div>
                  ) : null}
                  <div className="mr-5 text-center">
                    <div className="flex justify-center items-center">
                      <BsStarFill></BsStarFill>
                    </div>
                    <p style={{ fontSize: 10, marginBottom: 0 }}>
                      {card.rating
                        ? card.rating
                        : content &&
                          content.rating !== null &&
                          content.rating !== undefined
                        ? content.rating
                        : "?"}
                    </p>
                  </div>
                </>
              ) : content ? (
                <>
                  <div className="mr-5 text-center">
                    <div className="flex justify-center items-center">
                      <BsBarChartFill></BsBarChartFill>
                    </div>
                    <p style={{ fontSize: 10, marginBottom: 0 }}>
                      {content.nivel !== null && content.nivel !== undefined
                        ? content.nivel
                        : "?"}
                    </p>
                  </div>
                  <div className="mr-5 text-center">
                    <div className="flex justify-center items-center">
                      <BsClockFill></BsClockFill>
                    </div>
                    <p style={{ fontSize: 10, marginBottom: 0 }}>
                      {content.duracion !== null &&
                      content.duracion !== undefined
                        ? `${content.duracion} min`
                        : "?"}
                    </p>
                  </div>
                  <div className="mr-5 text-center">
                    <div className="flex justify-center items-center">
                      <BsStarFill></BsStarFill>
                    </div>
                    <p style={{ fontSize: 10, marginBottom: 0 }}>
                      {content.rating !== null && content.rating !== undefined
                        ? content.rating
                        : "?"}
                    </p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* Descripción debajo del card */}
      <div
        className="text-gray-700 text-sm truncate mt-2"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {card.descripcion}
      </div>
    </div>
  )
}

export default ContentCard
